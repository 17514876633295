export default class Utils {
  public static round(num: number): number {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  public static range(size: number, startAt: number = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  public static clone(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  public static truncate(str: string, length: number): string {
    return str.length > length
      ? str.substring(0, length - 1) + String.fromCharCode(0x2026)
      : str;
  }
}
